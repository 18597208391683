@import "../mixins/utilities";

.subscription-modal-backdrop {
  @include backdrop;
  z-index: 222;
  @include flex-column-center;

  .subscription-modal-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    min-height: 500px;
    overflow: hidden;
    align-items: center;
    padding-bottom: 20px;
    background-color: #f6f6f6;
    border-radius: 10px;
    border: 3px solid $color-black;
    position: relative;
    //background: url("../Assets/inky-arts/Halftone_TransparentRoundedSquares_White_Short.png");
    //background-position: bottom;

    .subscription-tone {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

    .subscription-modal-header-wrapper {
      width: 95%;
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: center;
      position: relative;

      .subscription-modal-header {
        font-size: 35px;
        @include flex-column-center;
        @include font-bold;
        flex: 1;
      }

      .subscription-modal-icon {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 3px;
        cursor: pointer;
        right: 0;
        margin-left: auto;
      }
    }

    .subscription-modal-describe {
      width: 75%;
      text-align: center;
      margin-top: 20px;
    }

    .plans-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 10px;
      width: 90%;

      .plan {
        @include flex-column;
        width: 100%;
        background-color: $color-white;
        border-radius: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        align-items: center;
        padding-bottom: 30px;
        z-index: 2;

        .plan-image {
          width: 100%;
          min-height: 250px;
        }

        .plan-duration {
          font-size: 18px;
          @include font-medium;
        }

        .plan-price-wrapper {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;

          .plan-price {
            font-size: 24px;
            @include font-bold;
          }

          .plan-limit {
            font-size: 20px;
          }
        }

        .plan-discount {
          font-size: 16px;
          @include font-x-light;
          margin-top: 10px;
          color: $color-pink;
        }
      }
    }
  }
}