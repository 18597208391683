@import "../mixins/utilities";

.pre-footer-container {
  width: 100%;
  padding: 60px 0;
  background-color: #e6e6e6;
  @include flex-column-center;

  .pre-footer-links-wrapper {
    display: flex;


    .pre-footer-link-wrapper {
      @include flex-column;
      align-items: center;

      .pre-footer-link-title {
        color: $color-text-secondary;
        @include font-bold;
        font-size: 15px;
        line-height: 10px;
      }

      .pre-footer-link {
        @include font-bold;
        @include router_link;
        font-size: 18px;
      }
    }

    .pre-footer-link-wrapper-second {
      margin-left: 30px;
    }
  }
}