@import "../../mixins/utilities";

.inky-publish-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 104px;
  padding-bottom: 100px;

  .list {
    @include font-x-light;

    p {
      margin-top: 5px;

      div {
        padding-left: 50px;
      }
    }
  }

  .divider {
    @include divider(2px);
    background-color: $color-gray-mid;
    margin-bottom: 10px;
  }

  .inky-publish-pink {
    width: 100%;
    height: auto;

    @media screen and (max-width: 1278px) {
      display: none;
    }
  }

  .inky-publish-max-wrapper {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1278px) {
      width: 85%;
      margin: 20px auto 0;
    }

    h5 {
      @include font-bold;
      margin-top: 20px;
      font-size: 20px;
    }

    h3 {
      @include font-bold;
      margin-top: 20px;
      font-size: 40px;
      letter-spacing: -1px;
    }

    .inky-second-title {
      font-size: 30px;
      letter-spacing: -1px;
      line-height: 10px;
      @include font-x-light;
      margin-top: 60px;

      @media screen and (max-width: 390px) {
        line-height: 25px;
      }
    }

    .inky-title {
      font-size: 50px;
      letter-spacing: -1px;
      @include font-bold;
      margin-top: 15px;
      line-height: 50px;
    }

    .publish-text {
      @include font-x-light;
      font-size: 23px;
      margin-top: 20px;
    }

    .publish-logo-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: fit-content;
      margin: 40px auto 30px auto;
      grid-column-gap: 80px;
      align-items: center;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 80px;
      }

      .publish-steam-logo {
        width: 200px;
      }
    }

    .get-in-touch-wrapper {
      @include flex-column;
      width: 90%;
      max-width: 800px;
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      margin: 50px auto 0 auto;
      padding: 30px 35px;
      border-radius: 10px;

      .get-in-touch-title {
        @include font-x-light;
        font-size: 25px;
      }

      .get-in-touch-text {
        @include font-x-light;
        font-size: 18px;
        margin-bottom: 0;

        strong {
          @include font-bold;
        }
      }
    }

    .inky-back-wrapper {
      width: 100%;
      display: flex;

      .inky-back {
        padding: 10px 20px 10px 5px;
        background-color: rgb(231, 231, 231);
        border-radius: 7px;
        position: relative;
        display: grid;
        grid-template-columns: 30px auto;
        cursor: pointer;

        .inky-back-text {
          @include font-x-light;
          margin-top: 0 !important;
        }

        .inky-back-icon {
          width: 25px;
        }
      }
    }

    .publish-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .send-feedback-wrapper {
      display: flex;
      flex-direction: column;

      .inkypen-email-wrapper input {
        width: 100%;
      }

      .send-feedback-input {
        textarea {
          margin-top: 20px;
          width: 100%;
          border: 2px solid rgb(171, 171, 171);
          border-radius: 5px;
          padding: 10px 10px;
          outline: none;
          -webkit-appearance: none;
        }

        textarea:-moz-placeholder, /* Firefox 18- */
        textarea::-moz-placeholder, /* Firefox 19+ */
        textarea:-ms-input-placeholder, /* IE 10+ */
        textarea::-webkit-input-placeholder, /* Webkit based */
        textarea::placeholder { /* Modern browsers */
          color: #0bf;
        }

      }
    }

    .send-wrapper {
      margin-top: 30px;
      width: fit-content;
      margin-left: auto;

      .send {
        @include inky-button;
        @include font-medium;
        padding: 10px 20px;
      }
    }
  }
}