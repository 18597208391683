@import "../../mixins/utilities";

.auth-login-container {
  @include flex-column;
  align-items: center;
  width: 100%;
  height: 100%;
  //background-color: #41b88a;
  overflow-y: auto;
  padding-bottom: 100px;
  //scrollbar-color: transparent transparent;
  //scrollbar-width: none;
  @include tiny-scroll;

  // Chrome support
  &::-webkit-scrollbar {
    @media screen and (min-width: 768px) {
      display: none;
    }
    //opacity: 1;
  }

  .auth-login-title {
    margin-top: 30px;
    width: 80%;
    @include font-bold;
    @include flex-column-center;
    font-size: 34px;
    line-height: 1.1;
  }

  .auth-login-subtitle {
    width: 80%;
    @include font-bold;
    margin-top:25px;
    font-size: 16px;
    line-height: 1.1;
  }

  .auth-login-title-2 {
    font-size: 28px;
    letter-spacing: -1px;
  }

  .auth-login-email-label {
    @include font-bold;
    font-size: 17px;
    width: 80%;
    margin-top: 40px;
    //@supports (-webkit-touch-callout: none) {
      //background-color: #41b88a;
    //}
  }

  .login-image {
    width: 80%;
    margin-top: 45px;
  }

  .auth-login-password-label {
    margin-top: 30px !important;
  }

  .auth-login-email-wrapper {
    width: 80%;
    margin-top: 5px;
  }

  .auth-password-type {
    //background-color: #41b88a;

    input[type="password"] {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }

  .password-strength {
    margin-top: 20px;
    @include font-bold;
  }

  .signup-password-strength-wrapper {
    width: 85%;
    margin: 3px auto 0 auto;

    .signup-password-strength-list-item {
      color: $color-black;
      text-decoration: line-through;
    }

    .signup-password-strength-list-item-red {
      //color: $color-red;
      text-decoration: none;
    }
  }

  .auth-bottom-buttons-wrapper{
    @include flex-row;
    width: 80%;
    justify-content: flex-end;
  }

  .auth-bottom-buttons-wrapper-checkout {
    @include flex-row;
    width: 80%;
    justify-content: flex-end;

    .auth-bottom-buttons-wrapper-checkout-inner {
    }
  }

  .auth-login-submit-wrapper-outer {
    width: 80%;
    display: flex;
    justify-content: flex-end;
  }

  .auth-login-submit-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;

    .arrow-container {
      width: 100px;
      height: 38px;
    }
  }

  .auth-continue-wrapper {
    height: 38px;
    margin-top: 20px;
  }


  .auth-login-features-wrapper {
    width: 80%;
    //@include flex-column;
    grid-row-gap: 10px;
    margin-top: 7px;
    display: grid;
    grid-template-rows: repeat(3, 1fr);

    .auth-login-feature {
      //@include flex-row;
      //align-items: center;
      width: 100%;
      height: auto;
      //column-gap: 7px;
      display: grid;
      grid-template-columns: 10px auto;
      grid-column-gap: 2px;
      align-content: space-evenly;

      .auth-login-feature-decoration {
        @include flex-column;
        width: 2px;
        height: 20px;
        min-height: 20px;
        border-radius: 1px;
        background-color: $color-black;
      }

      .auth-login-feature-text {
        @include font-light;
        font-size: 16px;
      }
    }
  }

  .auth-login-features-wrapper-checkout {
    @extend .auth-login-features-wrapper;
    grid-template-rows: repeat(2, 1fr);
  }

  .auth-login-verification-wrapper {
    @include flex-column-center;
    margin-top: 80px;

    .auth-login-feature-text {
      font-size: 14px;
    }

    .arrow-container {
      margin-top: 10px;
    }
  }

  .auth-divider {
    @include divider(2px);
  }

  .signup-accept-container {
    display: grid;
    grid-template-columns: auto;
    width: 80%;
    margin-top: 10px;

    .ui-checkbox-container .checkbox-label {
      display: block;
      font-size: 11pt;
      @include font-light;
      text-transform: uppercase;

      a, a:hover, a:visited {
        text-decoration: none;
        color: $color-black;
      }

      u {
        text-decoration: none;
        color: $color-pink;
        border-bottom: 2px solid $color-pink;
      }
    }
  }

}

