@import "../../mixins/utilities";
@import '../InkyPenLogin/InkyPenLogin';

.auth-signup-container {
  @include flex-column-center;
  width: 100%;
  height: auto;
  background-color: #8ea891;
}

.auth-signup-email-label {
  @extend .auth-login-email-label;
  margin-top: 20px !important;
}

.auth-signup-text {
  @include font-light;
  font-size: 15px;
  text-align: center;
  width: 55%;
  margin-top: 10px;
}