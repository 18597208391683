@import "../../mixins/utilities";

.ui-checkbox-container {
  @include flex-row;
  position: relative;
  margin-bottom: 12px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 35px;

  .checkbox-label {
    @include flex-column;
    height: auto;
    font-size: 18px;
    margin-top: 4px;
    margin-bottom: 0;
    cursor: pointer;
  }

  .checkbox-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;
    height: 0;
    width: 0;
  }

  .checkbox-selected-wrapper {
    width: 30px;
    @include flex-column;
    position: relative;
    align-items: center;
  }

  .checkbox-tick-icon {
    position: absolute;
    fill: $color-pink;
  }

  .checkbox-icon {
    width: 30px;
    cursor: pointer;
  }

  .checkbox-selected-icon {
    fill: $color-pink;
  }

  .checkbox-not-selected-icon {

  }

  .checkmark {
    @include flex-column-center;
    overflow: hidden;
    position: absolute;
    top: 5px;
    left: 0;
    border: 2px solid $color-pink;
    border-radius: 5px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    background-color: $color-white;
  }

  .checkmark-animation {
    transition: border 200ms ease-in-out, background-color 200ms ease-in-out;
  }

  .checkbox-input:checked ~ .checkmark {
    background-color: $color-pink;
    border: 11px solid $color-pink;
  }
}


