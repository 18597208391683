@import "../mixins/utilities";

.complete-menu-accounts {
  @include flex-column-center;
  width: 100%;
  @include tiny-scroll;

  .account-icon {
    width: 200px;
    height: auto;
    margin-top: 50px;
    fill: $color-pink;
  }

  .complete-new-menu-greeting {
    @include flex-column-center;
    @include font-bold;
    width: 100%;
    height: auto;
    //border-bottom: 2px solid $color-black;
    font-size: 16pt;
    //margin-top: 30px;
    //padding: 14px 0 10px 0;
  }

  .complete-new-menu-greeting-email {
    font-size: 12pt;
    margin-top: 0;
    @include font-x-light;
    padding: 0;
  }

  .account-verify-message-inner-wrapper {
    @include flex-column-center;
    border-bottom: 5px solid $color-black;
    width: 100%;
    padding: 30px 0;

    .account-verify-message-text {
      @include font-x-light;
      font-size: 14pt;
    }

    .arrow-container {
      width: fit-content;
      height: 50px;

      .arrow-btn {
        @include font-medium;
        font-size: 14pt;
        padding-top: 6px;
        text-align: start;
      }
    }
  }

  .complete-menu-accounts-nav {
    @include flex-column-center;
    width: 100%;
    color: $color-black;
    height: auto;
    text-align: end;
    text-decoration: none;

    @media (max-width: 2000px) {
      font-size: 24pt;
    }
  }

  .complete-menu-accounts-nav:nth-child(4) {
    border-bottom: none;
  }

  .complete-menu-accounts-nav-link {
    @include flex-column-center;
    justify-content: center;
    font-size: 24pt;
    @include font-medium;
    padding: 12px 0 10px 0;
    width: 100%;
    color: $color-black;
    height: auto;
    text-align: end;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-color: $color-pink;
      color: $color-white;
    }
  }

  .complete-menu-accounts-nav-link-top {
    border-top: 2px solid $color-gray-mid;
    margin-top: 70px;
  }

  .complete-menu-accounts-nav-link-logout {
    border-top: 2px solid $color-gray-mid;
    border-bottom: 2px solid $color-gray-mid;
  }

  .complete-menu-accounts-nav-dashboard {
    //color: $color-white;
    //background-color: $color-black;

    //&:hover {
    //  cursor: pointer;
    //  background-color: $color-white;
    //  color: $color-black;
    //}
  }
}


//.complete-menu-accounts {
//  @extend .complete-menu-sections;
//  display: grid;
//  grid-template-rows: repeat(2, 1fr);
//}
