@import "../mixins/utilities";
@import "../mixins/responsive";

.auth-backdrop {
  @include flex-row;
  position: fixed;
  z-index: 15000;
  width: 100vw;
  overflow-y: auto;
  height: 100vh;
  top: 0;
  left: 0;
  justify-content: flex-end;
  background-color: rgba(117, 117, 117, 0.45);

  .auth-modal-container {
    @include flex-column;
    @include tiny-scroll;
    width: 470px;
    height: 100%;
    min-height: 650px;
    max-height: 100vh;
    overflow-y: auto;
    background-color: white;
    opacity: 1;
    position: relative;


    .auth-header-wrapper {
      background-color: $color-pink;
      min-height: 100px;
      display: grid;
      grid-template-columns: 1fr 80px;

      .auth-header-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        margin-left: 20px;
        font-size: 30px;
        @include font-bold;
      }

      .auth-close-wrapper {
        border-left: 2px solid white;
        width: 100%;
        height: 100%;
        @include flex-column-center;
        cursor: pointer;

        .auth-close-icon {
          width: 25px;
          height: 25px;
          transform: rotate(45deg);
        }
      }
    }

    .auth-header-poster {
      width: 100%;
      height: auto;
      margin-top: -1px;

      //@media screen and (max-width: 768px) {
      //  display: none;
      //}
    }

    .link-account-steam {
      width: 80%;
      margin-top: 10px;

      .ui-checkbox-container .checkbox-label {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
      }
    }

    .auth-modal-cancel-wrapper {
      width: 80%;
      @include flex-column-center;
      align-items: center;
      margin: auto auto 0 auto;
      min-height: 150px;

      .guide-title {
        color: $color-text-secondary;
        @include font-bold;
        font-size: 14px;
      }

      .guide-text {
        font-size: 14px;
        @include font-bold;
      }

      .guide-title-margin {
        margin-top: 30px;
      }

      .auth-modal-cancel-text {
        @include flex-column-center;
        @include font-medium;
        border-bottom: 1px solid black;
        font-size: 13px;

        &:hover {
          cursor: pointer;
        }
      }

      .auth-modal-cancel-learn {
        text-align: end;

        @include flex-column-center;
        @include font-medium;
        border-bottom: 1px solid black;
        font-size: 13px;

        &:hover {
          cursor: pointer;
        }

        margin-left: auto;
        border-bottom: 2px solid #ABABAB;
        text-decoration: none;
        color: $color-black;


        @media screen and (min-width: 768px) {
          //margin-left: 30px;
          //text-align: start;
        }
      }
    }

    .auth-guide-wrapper {

    }

    .auth-guide-wrapper-top {
      margin-top: auto;
      display: flex;

      @media screen and (min-height: 750px) {
        //display: none;
      }
    }

    .auth-modal-cancel-wrapper-back {
      align-items: center;

      .auth-modal-cancel-learn {
        margin-top: 0 !important;
      }
    }

    .continue-as-guest-lock {
      .ui-underlined-text {
        color: #c5c5c5;
        border-color: #c5c5c5;
        cursor: default;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .auth-backdrop {
    .auth-modal-container {
      width: 100%;
      height: 100%;
      max-height: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }
}


@media screen and (max-width: 450px) {
  .auth-backdrop {
    .auth-modal-container {
      max-height: 100vh;

      //.auth-modal-cancel-wrapper {
      //  width: 80%;
      //  display: flex;
      //  flex-direction: column;
      //
      //  .auth-modal-cancel-text {
      //    //font-size: 12px;
      //  }
      //}
    }
  }
}

@media screen and (max-width: 576px) {
  .auth-backdrop {
    .auth-modal-container {

      //.auth-modal-cancel-wrapper {
      //  display: flex;
      //  flex-direction: column;
      //  height: 150px;
      //  padding: 10px 0;
      //  justify-content: center;
      //
      //  .ui-underlined-text {
      //    margin-left: auto;
      //  }
      //
      //  .auth-modal-cancel-learn {
      //    margin-top: 20px;
      //  }
      //}
    }
  }
}

@media screen and (max-width: 400px) {
  .auth-backdrop {
    .auth-modal-container {
      max-height: 100vh;

      //.auth-modal-cancel-wrapper {
      //  width: 80%;
      //
      //  .auth-modal-cancel-text {
      //    //font-size: 10px;
      //  }
      //}
    }
  }
}

@media screen and (max-width: 360px) {
  .auth-backdrop {
    .auth-modal-container {
      max-height: 100vh;

      //.auth-modal-cancel-wrapper {
      //  width: 80%;
      //
      //  .auth-modal-cancel-text {
      //    //font-size: 8px;
      //  }
      //}
    }
  }
}

// height medias


@media screen and (max-height: 650px) {
  .auth-backdrop {
    .auth-modal-container {
      min-height: 550px;
    }
  }
}

@media screen and (max-height: 550px) {
  .auth-backdrop {
    .auth-modal-container {
      min-height: 450px;
    }
  }
}

@media screen and (max-height: 450px) {
  .auth-backdrop {
    .auth-modal-container {
      min-height: 350px;
    }
  }
}

@media screen and (max-height: 350px) {
  .auth-backdrop {
    .auth-modal-container {
      min-height: 250px;
    }
  }
}