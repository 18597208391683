@import "../../mixins/utilities";

.ui-underlined-text {
  width: auto;
  height: auto;
  @include font-medium;
  font-size: 13px;
  text-transform: uppercase;
  border-bottom: 2px solid $color-gray-progressbar;

  &:hover {
    cursor: pointer;
  }
}