@import "../../mixins/utilities";

.inky-tos-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 104px;
  padding-bottom: 100px;

  .list {
    @include font-x-light;

    p {
      margin-top: 5px;

      div {
        padding-left: 50px;
      }
    }
  }

  .divider {
    @include divider(2px);
    background-color: $color-gray-mid;
    margin-bottom: 10px;
  }

  .inky-tos-pink {
    width: 100%;
    height: auto;

    @media screen and (max-width: 1278px) {
      display: none;
    }
  }

  .inky-tos-max-wrapper {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1278px) {
      width: 85%;
      margin: 20px auto 0;
    }

    h5 {
      @include font-bold;
      margin-top: 20px;
      font-size: 20px;
    }

    h3 {
      @include font-bold;
      margin-top: 20px;
      font-size: 40px;
      letter-spacing: -1px;
    }

    .inky-title {
      font-size: 50px;
      letter-spacing: -1px;
      @include font-bold;
      margin-top: 30px;
    }

    .inky-back-wrapper {
      width: 100%;
      display: flex;

      .inky-back {
        padding: 10px 20px 10px 5px;
        background-color: rgb(231, 231, 231);
        border-radius: 7px;
        position: relative;
        display: grid;
        grid-template-columns: 30px auto;
        cursor: pointer;

        .inky-back-text {
          @include font-x-light;
          margin-top: 0 !important;
        }

        .inky-back-icon {
          width: 25px;
        }
      }
    }

    .tos-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .send-feedback-wrapper {
      display: flex;
      flex-direction: column;

      .inkypen-email-wrapper input {
        width: 100%;
      }

      .send-feedback-input {
        textarea {
          margin-top: 20px;
          width: 100%;
          border: 2px solid rgb(171, 171, 171);
          border-radius: 5px;
          padding: 10px 10px;
          outline: none;
          -webkit-appearance: none;
        }

        textarea:-moz-placeholder, /* Firefox 18- */
        textarea::-moz-placeholder, /* Firefox 19+ */
        textarea:-ms-input-placeholder, /* IE 10+ */
        textarea::-webkit-input-placeholder, /* Webkit based */
        textarea::placeholder { /* Modern browsers */
          color: #0bf;
        }

      }
    }

    .send-wrapper {
      margin-top: 30px;
      width: fit-content;
      margin-left: auto;

      .send {
        @include inky-button;
        @include font-medium;
        padding: 10px 20px;
      }
    }
  }
}