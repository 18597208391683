@import "../mixins/utilities";


.inkypen-nintendo-code-container {
  @include flex-column;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 100vh;

  .inc__pink {
    width: 100%;
    height: auto;

    @media screen and (max-width: 1278px) {
      display: none;
    }
  }

  .inc__logo {
    width: 80%;
    max-width: 400px;


    @media screen and (max-width: 1578px) {
      max-width: 300px;
    }

    @media screen and (max-width: 1278px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 768px) {
      max-width: 300px;
    }
  }

  .inc__title {
    font-size: 60px;
    @include font-bold;
    max-width: 500px;
    text-align: center;
    line-height: 60px;

    @media screen and (max-width: 1578px) {
      font-size: 50px;
    }

    @media screen and (max-width: 768px) {
      font-size: 50px;
      line-height: 50px;
    }
  }

  .inc__instructions {
    font-size: 20px;
    margin-top: 50px;
    @include font-light;
    max-width: 520px;
    text-align: center;

    @media screen and (max-width: 768px) {
      width: 90%;
      margin: 50px auto 0 auto;
    }
  }

  .inc__code-input {
    border: 2px solid $color-gray-mid;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 30px;
    margin-top: 40px;
    outline: none;
    text-transform: uppercase;

    @media screen and (max-width: 1578px) {
      font-size: 25px;
    }

    @media screen and (max-width: 768px) {
      max-width: 70%;
    }

    &:focus {
      border-color: $color-pink;
    }

    &::placeholder {
      color: $color-text-secondary;
      text-transform: initial;
    }
  }

  .inc__error {
    color: $color-red;
    font-size: 17px;
    text-align: center;
    max-width: 400px;
    margin-top: 10px;

    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }

  .inc__submit-button {
    width: auto;
    margin-top: 25px;
    background-color: $color-pink;
    color: $color-white;
    padding: 10px 50px;
    font-size: 25px;
    @include font-bold;
    border: none;
    border-radius: 7px;
    margin-bottom: 40px;
  }

  .inc__pre-footer-wrapper {
    margin-top: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

