@import "../mixins/utilities";

.cp-forgot-password-container {
  @include backdrop;
  justify-content: flex-end;
  z-index: 222;
  align-items: center;

  @media screen and (min-width: 640px) {
    justify-content: center;
  }

  .cp-forgot-password-modal {
    @include flex-column;
    background-color: $color-white;
    min-height: 90vh;
    width: 100%;
    max-width: unset;
    height: 100%;
    border-top: 2px solid $color-black;
    align-items: center;
    position: relative;
    overflow-y: auto;
    @include tiny-scroll;

    @media screen and (min-width: 640px) {
      min-height: 650px;
      max-height: 95vh;
      max-width: 470px;
      border-top: none;
    }

    .auth-close-wrapper {
      @include border-b(3px);
      width: 35px;
      height: 35px;
      @include flex-column-center;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;

      .auth-close-icon {
        width: 25px;
        height: 25px;
        transform: rotate(45deg);
      }
    }

    .auth-header-poster-2 {
      width: 100%;
      height: auto;
      margin-top: -1px;
    }

    .cp-forgot-password-inner-wrapper {
      width: 90%;
      height: 100%;
      @include flex-column;

      .cp-forgot-password-email-timer-wrapper {
        @include flex-column;
        margin-top: 20px;
      }

      .forgot-password-time-remains {
        color: $color-text-secondary;
        font-size: 17px;
        @include font-x-light;
        margin-bottom: 20px;
      }

      .cp-forgot-password-title {
        margin-top: 30px;
        width: 80%;
        font-weight: 100;
        font-family: SourceSans3-Bold, SourceSans3-Regular-Bold, sans-serif;
        font-size: 34px;
        line-height: 1.1;
      }

      .cp-forgot-password-description {
        width: 100%;
        font-size: 14px;
        @include font-light;

        strong {
          letter-spacing: 0;
        };
      }

      .cp-forgot-password-description-2 {
        @extend .cp-forgot-password-description;
        margin-top: 15px;
      }

      .cp-forgot-password-forgot {
        margin-top: 0px;
      }

      .cp-forgot-password-input-wrapper {
        margin-top: 10px;
      }

      .cp-forgot-password-finish-buttons-wrapper {
        @include flex-row;
        column-gap: 30px;
        justify-content: flex-end;
        margin-top: 30px;
        align-items: center;
        margin-bottom: 20px;

        .ui-underlined-text {
          margin-top: 3px;
          @supports (-webkit-touch-callout: none) {
            margin-right: 20px;
          }
        }

        .arrow-container {
          width: 150px;

          span {
            @include font-bold;
          }
        }
      }
    }
  }
}

.cp-forgot-password-container-from-auth {
  @extend .cp-forgot-password-container;
  @include backdrop;
  justify-content: center;
  z-index: 222;
  align-items: center;

  @media screen and (min-width: 640px) {
    justify-content: center;
  }

  .cp-forgot-password-modal {
    max-height: 650px;
    @media screen and (max-width: 640px) {
      min-height: 100%;
      max-height: 100%;
      max-width: 100%;
      border-top: none;
    }
  }
}