@import "../../mixins/utilities";

.auth-signup-finished-header {
  font-size: 40px;
  @include font-bold;
  margin-top: 30px;
}

.auth-signup-finished-text-inkypen {
  width: 80%;
  margin-top: 30px;
  text-align: center;
  font-size: 17px;
  @include font-light;

  &.happyreading{
    margin-top:20px;
  }
}

.auth-signup-finished-text-inkypen-email {
  font-size: 17px;
}

.finish-signup-divider {
  margin-top: 20px;
  @include divider(2px);
  background-color: $color-gray-mid;
}

.finish-signup-button-styler {
  width: 90%;
  margin-top: 30px;

  .inkypen-button {
    font-size: 22px;
    padding: 10px 10px;
  }
}

.auth-signup-finished-front-wrapper {
  width: 80%;
  @include flex-row-center;
  margin: 25px auto 0 auto;
  text-transform: uppercase;

  .auth-signup-finished-front-text {
    @include flex-column-center;
    @include font-bold;
    font-size: 22px;

    &:hover {
      cursor: pointer;
    }
  }
}