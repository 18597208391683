@import "../../mixins/utilities";

.inkypen-button {
  width: 100%;
  height: auto;
  padding: 5px 10px;
  background-color: $color-pink;
  color: $color-white;
  display: grid;
  grid-template-columns: auto 40px;
  border: none;
  border-radius: 6px;
  justify-items: center;
  font-size: 20px;

  .inkypen-button-text {
    @include flex-column-center;
  }

  .inkypen-button-arrow {
    transform: rotate(180deg);
    fill: $color-white;
  }
}