@import "../../mixins/utilities";

.press-kit-container {
  padding-bottom: 100px;

  .article-spacer {
    width: 100%;
    height: 120px;
  }

 .article-spacer-small {
    width: 100%;
    height: 60px;
  }

  .steam-cta-button {
    outline: 0;
    transition: 100ms;
    font-size: 18px;
    @include font-bold;
    width: 100%;
    height: 60px;
    background-color: white;
    border-radius: 5px;
    border: 1.5px solid #e60075;
    box-shadow: rgba(0, 0, 0, 0.16) 2px 2px 6px;
    margin: 5px auto;

    &:hover {
      background-color: #e60075;
      color: white;
    }
  }

  .press-kit-wrapper {
    @include flex-column;
    display: flex;
    margin: 140px auto 0;
    width: 90%;
    max-width: 1440px;

    .inky-press-back-wrapper {
      width: fit-content;

      .inky-back {
        padding: 10px 20px 10px 5px;
        background-color: rgb(231, 231, 231);
        border-radius: 7px;
        position: relative;
        display: grid;
        grid-template-columns: 30px auto;
        cursor: pointer;

        .inky-back-text {
          @include font-x-light;
          margin-top: 0 !important;
        }

        .inky-back-icon {
          width: 25px;
        }
      }
    }
  }

  .summary-description-wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px auto 0;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 90%;
      max-width: 1440px;
      display: grid;
      grid-template-columns: 300px auto;
    }
  }

  .summary-kit-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.10);
    padding-bottom: 30px;

    @media screen and (min-width: 768px) {
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
    }

    .summary-kit-wrapper {
      margin: 10px auto 0;
      display: flex;
      flex-direction: column;
      width: 90%;
      max-width: 1440px;

      @media screen and (min-width: 768px) {
        width: 100%;
        max-width: unset;
      }

      .summary-wrapper {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .summary-title {
          @include font-bold;
          font-size: 18px;
          line-height: 26px;
          padding-right: 20px;
          @media screen and (min-width: 576px) {
            font-size: 22px;
          }
        }

        .summary-text {
          margin-top: 5px;
          @include font-x-light;
          font-size: 16px;
          padding-right: 0;
          @media screen and (min-width: 576px) {
            font-size: 18px;
            padding-right: 20px;
          }
        }
      }

      .download-assets {
        color: $color-pink;
        width: fit-content;
        @media screen and (min-width: 576px) {
          font-size: 20px;
        }
      }
    }
  }

  .description-kit-container {
    display: flex;
    flex-direction: column;
    margin: 10px auto 0;
    width: 90%;
    max-width: 1440px;

    @media screen and (min-width: 768px) {
      width: 100%;
      max-width: unset;
    }

    .description-kit-header {
      @include font-bold;
      margin-top: 0;
      font-size: 30px;
      line-height: 30px;

      @media screen and (min-width: 576px) {
        font-size: 35px;
        line-height: 40px;
      }
    }

    .divider {
      @include divider(2px);
      background-color: $color-gray-mid;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .description-kit-text {
      font-size: 17px;
      margin-top: 15px;
      @include font-x-light;

      strong {
        @include font-medium;
      }

      @media screen and (min-width: 576px) {
        font-size: 20px;
      }
    }

    .description-kit-text-italic {
      font-size: 17px;
      margin-top: 15px;
      @include font-x-light;
      font-style: italic;

      @media screen and (min-width: 576px) {
        font-size: 20px;
      }
    }

    .description-kit-text-strong {
      font-size: 15px;
      @include font-bold;
      margin-top: 10px;
    }

    .second-title {
      @include font-medium;
      font-size: 25px;
      margin-top: 20px;

      @media screen and (min-width: 576px) {
        font-size: 30px;
      }
    }

    .image-kit {
      width: 100%;
      height: auto;
    }

    .youtube-wrapper {
      width: 100%;
      margin: 0 auto;
      position: relative;
      padding-top: 56.25%;

      .youtube-embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .klaviyo-wrapper {
      width: 95%;
      margin: 10px auto;
    }

    .socials-wrapper {
      display: flex;
      justify-content: center;
      align-content: center;
      width: 100%;

      .socials-background {
        transition: 100ms;
        margin: 10px;
        height: 42px;
        width: 42px;
        border-radius: 10px;
        background-color: white;

        .socials-icon {
          padding: 5px;
          width: 100%;
          height: 100%;
          fill: black;

        }

        &:hover {
          background-color: #f1f1f1;
          scale: 1.2;

          .socials-icon {
            fill: #e60075;
          }
        }
      }


    }


    .inkypen-link {
      color: $color-blue;
    }
  }
}
