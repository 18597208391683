

.ImagePlaceholder {
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 0.67;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  opacity: 0.2;

  img {
    height: auto;
    width: 50%;
    &:hover {
      border: none !important;
    }
  }

}
