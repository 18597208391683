@import "../mixins/utilities";

.mobile-menu-container {
  @include backdrop;


  .mobile-menu-modal {
    @include flex-column;
    width: 95%;
    max-width: 300px;
    background-color: $color-white;
    position: absolute;
    right: 0;
    top: 0;
    min-height: 100vh;
    border-left: 2px solid $color-black;

    .mobile-menu-header-wrapper {
      background-color: $color-pink;
      min-height: 70px;
      display: grid;
      height: 100px;
      grid-template-columns: 1fr 80px;

      .mobile-menu-header-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        margin-left: 20px;
        font-size: 30px;
        height: 100%;
        @include font-bold;
      }

      .mobile-menu-close-wrapper {
        border-left: 2px solid white;
        width: 100%;
        height: auto;
        @include flex-column-center;
        cursor: pointer;

        .mobile-menu-close-icon {
          width: 25px;
          height: 25px;
          transform: rotate(45deg);
        }
      }
    }

    .mobile-menu-options-wrapper {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .mobile-menu-account-wrapper {
        display: grid;
        grid-template-columns: 40px auto;
        grid-column-gap: 10px;
        font-size: 15px;
        min-height: 100px;
        padding: 10px 5px 10px 10px;
        @include router_link;
        align-items: center;
        border-bottom: 2px solid $color-gray-mid;

        .mobile-menu-account-right-wrapper {
          display: flex;
          flex-direction: column;
        }
      }

      .mobile-menu-option {
        @include flex-column-center;
        min-height: 90px;
        border-bottom: 2px solid $color-gray-mid;
      }
    }

    .mobile-menu-links-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      padding-bottom: 15px;
      border-bottom: 2px solid $color-gray-mid;

      .mobile-menu-icon-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 10px;
        padding: 0 5px 0 10px;
        min-height: 60px;
        @include router_link;
        font-size: 18px;
        align-items: center;

        &:hover {
          background-color: $color-gray-light;
        }

        .nav-icon {
          width: 40px;
        }
      }

      .mobile-menu-icon-wrapper-active {
        @extend .mobile-menu-icon-wrapper;
        position: relative;
        color: $color-pink;

        .active-border {
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: $color-pink;
          border-radius: 5px;
          min-height: 2px;
          display: none;
        }

        .nav-icon {
          width: 40px;
          fill: $color-pink;
        }
      }

    }


    .mobile-menu-logout-wrapper {
      @extend .mobile-menu-links-wrapper;
    }
  }
}