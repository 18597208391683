@import "../../mixins/utilities";

.support-wrapper {
  @include flex-column;
  width: 85%;
  height: auto;
  margin-top: auto;
  margin-bottom: 10px;

  .support-need-help-text {
    width: 100%;
    height: auto;
    font-size: 15px;
  }

  .support-contact-support-text {
    width: fit-content;
    height: auto;
    color: $color-blue;
    @include font-light;
    font-size: 15px;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}