@import "../../mixins/utilities";

.auth-modal-forgot-password-wrapper {
  width: 80%;
  @include flex-row-center;

  margin: 15px auto 0 auto;
  min-height: 55px;

  .auth-modal-forgot-password {
    font-size: 14px;
    line-height: 23px;
    width: 100%;
    @include font-bold;
    //text-decoration: underline !important;
    //text-underline-offset: 7px;
    //text-decoration-color: $color-c6 !important;
  }

  .auth-login-forgot-wrapper {
    @include flex-row;
    width: 80%;
    justify-content: center;
    align-items: center;

    .arrow-container {
      width: 150px;
      height: 38px;
    }
  }
}
