@import "../mixins/utilities";

$color-white: #fff;
$color-gray-light: #f2f2f2;
$color-gray-mid: #ddd;
$color-gray-dark: #757575;
$color-black: #000;
$color-red: #f5333f;
$color-blue: #3cc8e1;
$color-orange: #ff9f37;
$color-purple: #c964cf;
$color-green: #14bfa1;
//$color-pink: #f67599;
$color-navy: #001a70;
$color-yellow: #ffe600;
$color-blue-rgb: 60, 200, 225;
$color-green-rgb: 20, 191, 161;
$color-navy-rgb: 0, 26, 112;
$color-orange-rgb: 255, 159, 55;
$color-pink-rgb: 246, 117, 153;
$color-purple-rgb: 201, 100, 207;
$color-red-rgb: 245, 51, 63;
$color-yellow-rgb: 255, 230, 0;

$color-background: $color-white;
$color-background-2: $color-gray-light;
$color-background-3: $color-gray-mid;
$color-text: $color-black;
$color-text-2: $color-gray-dark;
$color-line: $color-black;
$color-line-2: $color-gray-dark;
$color-line-3: $color-gray-mid;

$measure: 80ch;


$line-height: 1.3;
$line-height-heading: 1.1667;
$font-family-sans-serif: SourceSans3-Regular, "SourceSans3-Regular", "Century Gothic", "Futura", helvetica, arial, sans-serif;
$font-family-serif: "Galaxie Copernicus", "Palatino Linotype", "Book Antiqua", "Palatino", serif;
$font-family-monospace: "Courier 10 Pitch", courier, monospace;
$font-family-code: monaco, consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$type-scale: 1.125;
$font-size-01: calc(var(--font-size-02) / var(--type-scale));
$font-size-02: calc(var(--font-size-03) / var(--type-scale));
$font-size-03: calc(var(--font-size-04) / var(--type-scale));
$font-size-04: 1rem;
$font-size-05: $font-size-04 * $type-scale;
$font-size-06: calc(var(--font-size-05) * var(--type-scale));
$font-size-07: calc(var(--font-size-06) * var(--type-scale));
$font-size-08: calc(var(--font-size-07) * var(--type-scale));
$font-size-09: calc(var(--font-size-08) * var(--type-scale));
$font-size-10: calc(var(--font-size-09) * var(--type-scale));
$font-size-11: calc(var(--font-size-10) * var(--type-scale));
$font-size-12: calc(var(--font-size-11) * var(--type-scale));
$font-size-13: calc(var(--font-size-12) * var(--type-scale));
$font-size-14: calc(var(--font-size-13) * var(--type-scale));
$font-size-15: calc(var(--font-size-14) * var(--type-scale));
$font-size-16: calc(var(--font-size-15) * var(--type-scale));
$font-size-17: calc(var(--font-size-16) * var(--type-scale));
$font-size-18: calc(var(--font-size-17) * var(--type-scale));
$font-size-xxx-smaller: calc(var(--font-size-xx-smaller) / var(--type-scale));
$font-size-xx-smaller: calc(var(--font-size-x-smaller) / var(--type-scale));
$font-size-x-smaller: calc(var(--font-size-smaller) / var(--type-scale));
$font-size-smaller: calc(1em / var(--type-scale));
$font-size-icon-default: 2.375rem;
$small-caps-modifier: 0.8;
$font-size-small-caps-03: calc(var(--font-size-03) * var(--small-caps-modifier));
$font-size-small-caps-04: calc(var(--font-size-04) * var(--small-caps-modifier));
$font-size-small-caps-05: calc(var(--font-size-05) * var(--small-caps-modifier));
$space-01: calc(var(--space-02) / 2);
$space-02: calc(var(--space-03) / 2);
$space-03: calc(var(--space-04) / 2);
$space-05: $font-size-05 * $line-height;
$space-04: calc($space-05 / 2);
$space-06: $space-05 * 2;
$space-07: $space-06 * 2;
$space-08: calc(var(--space-07) * 2);
$space-line: calc(var(--font-size-04) * var(--line-height));
$border-width-sm: 0.09375rem;
$border-width-md: 0.125rem;
$border-width-lg: 0.25rem;
$box-shadow-md: 0.125rem 0.25rem 0.3125rem 0 rgba(0, 0, 0, 0.2);
$focus-shadow-size: var(--border-width-lg);
$site-header-bump: calc(4.0625rem + var(--space-04) * 2);
$flow-gap-default: var(--space-05);
$flow-gap: var(--flow-gap-default);
$flow-gap-all: initial;
$site-top-bump: 10rem;

* {
  margin: 0;
  padding: 0;
}

.spacer {
  height: 5.1rem;
}


.navbar-backdrop {
  position: fixed;
  z-index: 110;
  background-color: transparent;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.navbar-backdrop-search {
  @extend .navbar-backdrop;
  z-index: -1;
}

.search-backdrop {
  @include backdrop;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  z-index: 101;
}

.InkypenNavbar {
  left: 0;
  top: 0;
  //padding: $space-04;
  z-index: 111;
  //padding: 0.5em;
  position: fixed;
  right: 0;
  max-height: 100%;
  height: fit-content;
  overflow-y: auto;
  background-color: transparent;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  //border-bottom: 1px solid $color-black;
  //border-right: 1px solid $color-black;

  scroll-behavior: smooth;
  scrollbar-color: $color-black transparent;
  scrollbar-width: thin;

  a {
    outline: none;
  }

  .scroll-wrapper {
    //max-height: 86vh;
    //overflow-y: auto;
    //background-color: transparent;
    //border-bottom: 1px solid $color-black;
    //border-right: 1px solid $color-black;
    //scroll-behavior: smooth;
    //scrollbar-color: $color-black transparent;
    //scrollbar-width: thin;
  }


  .beta-bug {
    border-radius: 12px;
    //border: 2px solid black;
    align-self: center;
    height: 22px;
    font-size: 12px;
    font-family: SourceSans3-SemiBold, 'SourceSans3-Regular-Medium', sans-serif;
    text-align: right;
    margin-right: 22px;
    margin-top: 2px;
    padding: 3px 20px 0px 20px;
    background-color: #dddddd;
  }


  .inner-navbar {
    background-color: $color-white;
    display: flex;
    justify-content: space-between;
    //border: 2px solid #000000;
    padding: $space-04 $space-05 $space-04 $space-04;
    justify-items: center;

    @media screen and (max-width: 1024px) {
      padding: $space-04 $space-04 $space-04 $space-04;
    }

    @media (max-width: 1024px) {
      .beta-bug {
        display: none;
      }
    }

    .beta-bug {
      margin-left: auto;
    }

    .logo {
      height: 100%;
      min-height: 77px;
      width: auto;
      align-self: center;
      margin-left: auto;

      @media screen and (max-width: 1200px) {
        height: 80px;
        width: auto;
      }
    }

    .navbar-wrapper {
      width: 65%;
      display: flex;
      //justify-content: space-between;
      //justify-items: center;
      margin: 0 auto;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }
    }

    .nav-for-mobile {
      display: grid;
      align-items: center;
      margin-left: auto;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      margin-right: 20px;
    }

    .hide-nav-on-desktop {
      @media screen and (min-width: 1201px) {
        display: none;
      }
    }

    .menu-icon {
      width: 40px;
      cursor: pointer;
    }

    .mobile-search-bar {
      position: fixed;
      top: 104px;
      left: 0;
      background-color: #41b88a;
      width: 100%;
      height: auto;
      z-index: 111999;
    }

    .logo-more-420 {
      display: none;
      @media screen and (min-width: 420px) {
        display: flex;
      }
    }

    .logo-less-420 {
      display: none;
      @media screen and (max-width: 419px) {
        display: flex;
      }
    }

    nav {

      justify-content: space-between;

      align-items: center;

      .div-instead-of-ul-desktop {
        list-style: none;
        display: flex;

        > :nth-child(4) {
          margin-right: 15px;
        }

        .list-menu-and-cart {
          margin: 0 0 0 5px;
        }

        .route-text-active {
          color: $color-pink;
        }

        a {
          user-select: none;
          font-family: SourceSans3-SemiBold, "SourceSans3-Regular-Medium", sans-serif;
          padding: 6px 10px 4px 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          align-content: center;
          text-align: center;
          @include font-medium;
          font-size: 17px;
          color: black;
          text-decoration: none;

          .route {

            @media (max-width: 1000px) {
              font-size: 0.95rem;
            }
          }


          &.button {
            background-color: $color-gray-mid;
            border-radius: 5px;
            font-size: 12px;
            text-transform: uppercase;
            color: black;
            text-decoration: none;
            letter-spacing: 2px;
            line-height: 20px;

            @media (hover: hover) {
              &:hover {
                color: white;
                background-color: black;
              }
            }
          }
        }

        .list-menu-and-cart {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          align-content: center;
          text-align: center;
          @include font-medium;
          font-size: 17px;

          .route {

            @media (max-width: 1000px) {
              font-size: 0.95rem;
            }
          }

          .card-quantity-number {
            border: 2px solid black;
            font-size: 12px;
            margin-left: 0;
            height: 100%;
            border-left: none;
            @include font-bold;
            @include flex-column-center;
            padding: 8px 10px 4px 10px;
            line-height: 20px;

            @media (min-width: 1001px) {
              font-size: 14px;
              height: unset;
            }
          }

          .menu-not-verified {
            border: 2px solid black;
            font-size: 12px;
            margin-left: 0;
            height: 100%;
            padding: 8px 14px 4px 14px;
            border-right: none;
            @include font-bold;
            background-color: $color-red;
            color: $color-white;
            line-height: 20px;
            @include flex-column-center;

            @media (min-width: 1001px) {
              height: unset;
            }
          }

          .hide-menu-when-logged-out {
            display: none;
            @media screen and (max-width: 1024px) {
              display: flex;
            }
          }

          .account-icon {
            width: 40px;
            fill: $color-pink;
          }

          .login-when-logged-out-large-screens {
            //display: none;
            //@media screen and (min-width: 1025px) {
            //  display: flex;
            //}
            width: 85px;
            height: 40px;
            @include flex-column-center;
            letter-spacing: 0;

            &:hover {
              background-color: $color-pink;
            }
          }

          .login-when-screens {
            display: none;
          }


          .menu-when-small-screens {
            display: none;
          }

          .menu-when-logged-out-small-screens {
            display: none;
            @media screen and (max-width: 1024px) {
              display: flex;
            }
          }
        }
      }

      .nav-icon-container {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: 10px;

        a {
          padding: 0;
        }

        .nav-icon-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding: 5px 7px;

          &:hover {
            background-color: $color-gray-light;
            border-radius: 5px;
            color: $color-pink;

            .nav-icon {
              fill: $color-pink;
            }
          }

          .nav-icon {
            width: 35px;
          }

          .nav-icon-my-comics {
            width: 30px;
          }
        }

        .nav-icon-wrapper-active {
          display: flex;
          flex-direction: row;
          justify-content: center;
          //border-bottom: 2px solid $color-pink;
          padding: 5px 7px;
          position: relative;
          color: $color-pink;

          .active-border {
            position: absolute;
            bottom: 5px;
            width: calc(100% - 20px);
            background-color: $color-pink;
            border-radius: 5px;
            right: 8px;
            min-height: 2px;
          }

          .nav-icon {
            width: 35px;
            fill: $color-pink;
          }

          .nav-icon-my-comics {
            width: 30px;
          }
        }
      }
    }


    .menu-links-wrapper {
      margin-right: auto;
      margin-left: 20px;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    .menu-login-wrapper {
      margin-left: auto;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    .open-the-search-bar-wrapper {
      width: 100%;
      padding-left: 20px;
      margin-left: auto;
      flex-direction: row-reverse;

      .menu-search-input-wrapper {
        width: 100%;
        margin-top: 0;
        height: auto;
        position: relative;
        @include flex-row;
        margin-right: auto;

        .email-input {
          border-width: 2px !important;
        }

        svg {
          position: absolute;
          left: calc(100% + 5px);
          top: 7px;
          cursor: pointer;
        }
      }
    }

    .open-the-search-bar {
      width: 100%;

      .inkypen-email-wrapper {
        width: 100%;
      }
    }
  }


  .beta-bug-show-on-mobile {
    border-bottom: 2px solid black;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .beta-bug {
      margin: 0px;
    }

    @media (min-width: 1024px) {
      .beta-bug {
        display: none;
      }
    }
  }

  @media (min-width: 1024px) {
    .beta-bug-show-on-mobile {
      display: none;
    }
  }

  .inner-menu {
    @media screen and (min-width: 1025px) {
      display: none;
    }

    //border: 2px solid black;
    //border-top: 1px solid black;
    background-color: white;
    overflow-y: scroll;
    max-height: 85vh;

    .divider {
      width: 100%;
      height: 1px;
      background-color: black;
      min-height: 2px;
    }

    .account-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: white;
      height: auto;
      @include font-medium;

      .account-verify-message-inner-wrapper {
        @include flex-column-center;
        width: 100%;
        padding: 0 0 20px 0;

        .account-verify-message-text {
          @include font-x-light;
          font-size: 18px;
        }

        .arrow-container {
          width: fit-content;
          height: 40px;

          .arrow-btn {
            @include font-medium;
            font-size: 16px;
            padding-top: 6px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: start;
          }
        }
      }

      .sign-in-create-account {
        cursor: pointer;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
        align-items: center;
        background-color: $color-black;
        justify-content: center;

        .login-or-signup {
          @include font-bold;
          font-size: 17pt;
          color: white

        }


        .log-sign-between {
          background-color: $color-white;
          min-height: 22px;
          min-width: 4px;
          margin: 0 10px 4px 10px;
        }
      }

      .divider {
        @extend .divider;
        min-height: 1px;
        height: 1px;
      }

      .divider-2 {
        @extend .divider;
        min-height: 2px;
        height: 2px;
      }

      .username-text {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60px;
        height: 60px;
      }

      .library-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 50px;
        min-height: 50px;

        .library-text {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: auto;
          color: $color-black;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .library-text-not-logged-in {
          justify-content: flex-start;
          padding-left: 10px;
        }

        .divider-v {
          width: 1px;
          min-width: 1px;
          height: 100%;
          background-color: black;
        }

        .account-text {
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          width: 100%;
          height: auto;
        }

        .account-text-not-active {
          background-color: $color-f1;
          color: $color-text-secondary;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .login-section {
      color: white;
      background-color: black;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 1.5em;
    }

    .menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;

      .complete-menu-accounts-nav-dashboard {
        color: $color-white;
        background-color: $color-black;
        @include flex-column-center;
        min-height: 55px;
        display: flex;
        align-items: center;
        height: 55px;
        padding-top: 3px;
        padding-left: 10px;
      }

      .div-instead-of-ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin: 0;
        list-style-type: none;

        .route {
          min-height: 55px;
          display: flex;
          align-items: center;
          height: 55px;
          @include font-medium;
          padding-top: 3px;
          padding-left: 10px;
        }

        .subMenu ul {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;

          .divider {
            @extend .divider;
            height: 1px;
            min-height: 1px;
          }

          .route {
            padding-left: 20px;
          }

          .route-first {
            border-bottom: 1px solid $color-black;
          }
        }

        .divider-bold {
          width: 100%;
          height: 4px;
          min-height: 4px;
          background-color: black;
        }
      }
    }

    .logout-wrapper {
      width: 100%;
      height: auto;
      background-color: white;
      display: flex;
      justify-content: center;
      min-height: 55px;
      border-top: 1px solid $color-black;
      margin-top: 0;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      .logout-text {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .convert-mobile-menu-to-search {
    @include flex-column-center;
    width: 100%;
    height: auto;
    background-color: $color-white;
    //border-bottom: 2px solid $color-black;
    @media screen and (max-width: 1024px) {
      //border: none;
      //display: none;
    }
    border-top: none;
  }

  .dropdown {
    &.hidden {
      max-height: 0;
      transition: max-height 125ms ease-in-out, visibility 125ms ease-in-out, opacity 125ms ease-in-out;
      opacity: 0;
      visibility: hidden;
    }

    overflow: hidden;
    //border: 2px solid black;
    border-top: none;
    width: 100%;
    height: 100%;

    //background-color: white;
    background-color: transparent;
    position: relative;
    transition: max-height 125ms ease-in-out, visibility 125ms ease-in-out, opacity 125ms ease-in-out;
  }

}

.complete-menu-container {
  @media screen and (max-width: 1024px) {
    display: none;
  }
  width: 30%;
  min-height: 300px;
  margin-left: auto;
  @include border-b(2px);
  border-top: none;
  @include flex-row;
  background-color: $color-white;

  .complete-menu-sections {
    width: 100%;
    height: auto;
    @include font-bold;
  }


  .complete-menu-assets {
    @include flex-column-center;
    @extend .complete-menu-sections;
    font-size: 14pt;
    flex-basis: calc(100% * (4.5 / 10));
    flex-shrink: 0;
    border-right: 2px solid $color-black;
    background-color: #ECECEC;
  }

  .complete-menu-greeting {
    @include flex-column-center;
    @extend .complete-menu-sections;
    font-size: 27pt;
    @media (max-width: 2000px) {
      font-size: 20pt;
    }
    flex-shrink: 0;
    flex-basis: calc(100% * (3.0 / 10));
    border-right: 4px solid $color-black;
  }
}

.complete-menu-accounts-user-not-loggedIn {
  min-height: 150px;

  .complete-menu-accounts-nav:nth-child(2) {
    border-bottom: none;
  }

  .complete-menu-assets {
    flex-basis: calc(100% * (7.5 / 10));
  }
}

.inner-navbar a { //
  display: flex;
}

.inner-navbar ul {
  margin: 0;
}

@media only screen and (min-width: 1025px) {

  .InkypenNavbar .inner-navbar .logo {
    height: 60px;
  }
}


.menu-search-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $color-white;

  @media screen and (min-width: 1200px) {
    width: 55%;
  }

  .menu-search-wrapper-desktop {
    display: none;

    @media screen and (min-width: 1201px) {
      display: flex;
    }
  }

  .menu-search-wrapper-mobile {
    display: none;

    @media screen and (max-width: 1200px) {
      display: flex;
      width: 95%;
    }
  }

  .menu-search-text {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 10px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: black;
    min-height: 2px;
  }

  .menu-search-input-wrapper {
    width: 75%;
    background-color: white;
    margin-top: 0;
    height: auto;
    margin-bottom: 10px;
    position: relative;
    @include flex-row;

    .email-input {
      border-width: 3px !important;
    }

    svg {
      position: absolute;
      left: calc(100% + 5px);
      top: 7px;
      cursor: pointer;
    }
  }

  .menu-search-input-wrapper-mobile {
    width: 100%;
  }
}

.recent-search-container {
  @include flex-column;
  width: 90%;
  height: auto;
  padding-bottom: 10px;
  background-color: $color-white;

  @media screen and (min-width: 1025px) {
    width: 65%;
  }

  @media screen and (min-width: 1200px) {
    width: 45%;
  }

  .clear-search-wrapper {
    @include flex-row;
    align-items: center;
    margin-top: 10px;

    .recent-search-text {
      font-size: 25px;
      @include font-bold;

      @media screen and (max-width: 1024px) {
        font-size: 17pt;
        margin-left: 10px;
      }
    }

    .clear-search-text {
      margin-left: auto;
      font-size: 20px;
      cursor: pointer;
    }

    .ui-underlined-text {
      height: 100%;
      margin-left: auto;
      @media screen and (max-width: 1024px) {
        margin-right: 20px;
      }
    }
  }

  .recent-search-items-container {
    @include flex-column;
    width: 100%;
    height: auto;
    margin-top: 20px;
    padding-bottom: 20px;


    @media screen and (max-width: 1024px) {
      padding-bottom: 0;
      > :last-child {
        border-bottom: none !important;
      }
    }

    .recent-search-item-wrapper {
      @include flex-row;
      width: 100%;
      height: auto;
      min-height: 35px;
      align-items: center;
      border-bottom: 1px solid $color-black;
      padding-left: 20px;
      background-color: $color-white;
      padding-top: 4px;
      cursor: pointer;

      .recent-search-item-text {
        @include flex-row;
        align-items: center;
        height: auto;
        font-size: 15pt;
        @include font-light;
        margin-left: 15px;
        @include nth-line-text(1);

        @media screen and (max-width: 450px) {
          font-size: 12pt;
        }
      }

      svg {
        flex-shrink: 0;
      }
    }
  }
}

.search-message-wrapper {
  @include flex-column-center;
  width: 100%;
  height: 55px;
  min-height: 55px;

  .search-message-text {
    @include flex-column-center;
    width: 100%;
    height: 100%;
    margin-top: 0;
    color: $color-text-secondary;
    //padding-top: 5px;
  }
}

.search-result-wrapper {
  @include flex-column;
  height: 350px;
  width: 91%;
  padding-bottom: 30px;
  margin: 20px auto 0 auto;
  overflow: auto;
  @include tiny-scroll;

  @media screen and (min-width: 1025px) {
    width: 55%;
  }

  .search-result-item-wrapper {
    display: grid;
    grid-template-columns: 70px auto;
    grid-column-gap: 10px;
    width: 100%;
    align-items: start;
    height: auto;
    cursor: pointer;
    padding: 10px 5px;

    .search-result-info-wrapper {
      width: 100%;
      @include flex-column;

      .search-result-type {
        @include font-medium;
        font-size: 15pt;
        line-height: 20px;
        color: $color-text-secondary;
      }

      .search-result-name {
        @include font-bold;
        font-size: 18pt;
        line-height: 25px;
      }

      .sale {
        position: absolute;
        top: 0;
        left: 0;
        @include font-medium;
        color: $color-white;
        background-color: $color-black;

        width: fit-content;
        padding: 6px 7px 0 7px;
        height: 27px;
        font-size: 12px;
        font-weight: 100;
        font-family: SourceSans3-SemiBold, SourceSans3-Regular-Medium, sans-serif;
        z-index: 1;
        letter-spacing: 1px;
      }

    }
  }
}
